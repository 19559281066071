import React, { Component, Fragment } from "react";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import { slideSlick, slideSlick2 } from "../page-demo/script";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Team from "../blocks/team/TeamTwo";
import Accordion01 from "../elements/Accordion";
import Helmet from "../component/common/Helmet";
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiChevronUp,
  FiCheck,
} from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import BrandOne from "../elements/Brand";

const SlideList = [
  {
    textPosition: "text-right",
    bgImage: "bg_image--30",
    category: "",
    title: "Santiago 76",
    description: "",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--31",
    category: "",
    title: "Food Mart",
    description: "",
    buttonText: "Read More",
    buttonLink: "/service-details",
  },
  {
    textPosition: "text-right",
    bgImage: "bg_image--33",
    category: "",
    title: "Car Wash",
    description: "",
    buttonText: "Read More",
    buttonLink: "/service-details2",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--32",
    category: "",
    title: "Wireless Store",
    description: "",
    buttonText: "Read More",
    buttonLink: "/service-details3",
  },
];

const SlideList2 = [
  {
    bgImage: "bg_image--26",
  },
  {
    bgImage: "bg_image--25",
  },
  {
    bgImage: "bg_image--24",
  },
  {
    bgImage: "bg_image--23",
  },
];

const ServiceListOne = [
  {
    icon: <FiCast />,
    title: "Check Cashing",
    description:
      "Easy and convenient, get money when you need it. Checks cashed as low as 1.25%.  Moneygram wire transfer and money orders also available.",
  },
  {
    icon: <FiLayers />,
    title: "Boost Mobile",
    description:
      "Keep more money in your pocket thanks to awesome phone deals on Boostmobile, Located inside Santiago Hand Wash building.",
  },
  {
    icon: <FiUsers />,
    title: "Auto Registration",
    description:
      "Renew, replace, or transfer your vehicle registration and avoid a trip to the DMV.  Located inside Santiago Food Mart.",
  },
];

const starndardService = [
  {
    image: "01",
    title: "Food Mart",
    description:
      "Inside the food mart we have Check Cashing, Money Gram and Auto Registration",
  },
  {
    image: "02",
    title: "Wireless",
    description:
      "Santiago Wireless is your one-stop convenience wireless location allowing you to find your next cellular phone eaiser.",
  },
  {
    image: "03",
    title: "Hand Wash",
    description:
      "We welcome you to Santiago Hand Wash. Since opening in 1996, we have grown to provide our customers with the best quality hand wash experience.",
  },
];

class CorporateBusiness extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    var namesItemOne = [
      "Monday: 10am - 7pm",
      "Tuesday: 10am - 7pm",
      "Wednesday: 10am - 7pm",
      "Thursday: 10am - 7pm",
      "Friday: 10am - 7pm",
      "Saturday: 10am - 5pm",
      "Sunday: Closed",
    ];
    var namesItemTwo = [
      "Monday: 8am - 6pm",
      "Tuesday: 8am - 6pm",
      "Wednesday: 8am - 6pm",
      "Thursday: 8am - 6pm",
      "Friday: 8am - 6pm",
      "Saturday: 8am - 6pm",
      "Sunday: 8am - 6pm",
    ];

    const PostList = BlogContent.slice(0, 3);

    return (
      <Fragment>
        <Helmet pageTitle="Santiago 76" />

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title">{value.title}</h1>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p className="description">{value.description}</p>
                          ) : (
                            ""
                          )}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-solid"
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area */}
        <div className="service-area ptb--30 bg_color--1">
          <div className="container">
            <div className="row service-one-wrapper">
              {ServiceListOne.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <a className="text-center">
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start Featured Service Area  */}
        <div className="rn-featured-service-area pt--90 pb--120 bg_color--5">
          <div className="container">
            <div className="row">
              {/* Start Single Service  */}
              <div className="col-lg-3 col-md-6 col-12 mt--30">
                <div className="section-title">
                  <h2 className="title">Services</h2>
                  <p>
                    We do it all! From Hand Wash, Check Cashing, Wireless Store
                    and Auto Registration all in one location.
                  </p>
                  <div className="service-btn">
                    <a className="btn-transparent rn-btn-dark" href="/service">
                      <span className="text"></span>
                    </a>
                  </div>
                </div>
              </div>
              {/* End Single Service  */}

              {/* Start Single Service  */}
              <div className="col-lg-9">
                <div className="row">
                  {starndardService.map((value, index) => (
                    <div className="col-lg-4 col-md-4 mt--30" key={index}>
                      <div className="standard-service">
                        <div className="thumbnai">
                          <img
                            src={`/assets/images/featured/corporate-${value.image}.jpg`}
                            alt="Corporate Images"
                          />
                        </div>
                        <div className="content">
                          <h3>
                            <a href="/service-details">{value.title}</a>
                          </h3>
                          <p>{value.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* End Single Service  */}
            </div>
          </div>
        </div>
        {/* End Featured Service Area  */}

        {/* Start Counterup Area */}
        {/* <div
          className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white"
          data-black-overlay="7"
        >
          <div className="container">
            <CounterOne />
          </div>
        </div> */}
        {/* End Counterup Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail mt-3 h-75">
                  <img
                    className="w-100"
                    src="/assets/images/about/about-8.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Checkout Our New Store!</h2>
                    <p>
                      Complete re-model finished in 2022 for a new and exciting
                      look.
                    </p>
                  </div>
                  <div className="accordion-wrapper mt--30">
                    <Accordion01 />
                  </div>
                  <div className="about-button mt--50">
                    <a className="rn-button-style--2 btn-solid" href="/about">
                      See our history
                    </a>
                  </div>
                  {/* <div className="thumbnail mt--50">
                    <img
                      className="w-100"
                      src="/assets/images/about/about-4.jpg"
                      alt="About Images"
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start New Store Image Slider */}
        <div className="slider-wrapper">
          <div className="slider-activation w-75 m-auto">
            <Slider className="rn-slick-dot dot-light" {...slideSlick2}>
              {SlideList2.map((value, index) => (
                <div
                  className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title">{value.title}</h1>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p className="description">{value.description}</p>
                          ) : (
                            ""
                          )}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-solid"
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End New Store Image Slider */}

        {/* Start Team Area  */}
        {/* <div className="rn-team-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                  <h2 className="title">Our Team</h2>
                  <p>
                    We have the best team at Santiago 76 that will take care of
                    all your needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <Team
                column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30"
                teamStyle=""
                item="4"
              />
            </div>
          </div>
        </div> */}
        {/* End Team Area  */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">About Us</h2>
                    <p className="description">
                      Located on I-5 & 17th Street next to Guaranty Chevrolet,
                      Santiago Food Mart and Hand Wash has been servicing our
                      customers in Santa Ana since 1996. Family owned and
                      operated, we are known for our quality control, your 100%
                      satisfaction, and being committed to quality service and
                      value. Being a family owned business allows us to adapt to
                      the communities request on a daily basis. We appreciate
                      our customers input on what they would like to see in our
                      store to help make our corner a higher quality place of
                      business
                    </p>
                    <p className="description">
                      Most of our team here at Santiago Food Mart and Hand Wash
                      have been working for us for over 20 years. Our managers
                      Manny Alonso and Santiago Mondragon have been with us for
                      over 22 years and are always happy to assist you. If there
                      is something you need, please don’t hesitate to ask.
                    </p>
                  </div>
                  <div className="mt--30">
                    <h3>Check Cashing / Auto Registration Hours</h3>
                    <ul className="list-style--1">
                      {namesItemOne.map((name, index) => {
                        return (
                          <li key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="mt--30">
                    <h3 className="mb-0">Car Wash Hours</h3>
                    <h6 className="mb-1">(Weather Permitting)</h6>
                    <ul className="list-style--1">
                      {/* <li>
                        <strong>
                          Winter Hours: 8am - 5pm (Last Car at 4:30pm)
                        </strong>
                      </li>
                      <li>
                        <strong>Summer Hours 8am-6pm (Last Car at 5:30pm)</strong>
                      </li> */}
                      <li>
                      <h5 className="mb-0 mt-3">Winter Hours</h5>
                        <FiCheck />8am - 5pm (Last Car at 4:30pm) 
                      </li>
                      <li>
                        <h5 className="mb-0"> Summer Hours</h5>
                        <FiCheck />8am-6pm (Last Car at 5:30pm)
                      </li>
                      {/* {namesItemTwo.map((name, index) => {
                        return (
                          <li key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })} */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail position-relative">
                  <img
                    className="w-100"
                    src="/assets/images/about/about-1.png"
                    alt="About Images"
                  />
                  <ModalVideo
                    channel="youtube"
                    isOpen={this.state.isOpen}
                    videoId="ZOoVOfieAF8"
                    onClose={() => this.setState({ isOpen: false })}
                  />
                  {/* <button
                    className="video-popup position-top-center theme-color"
                    onClick={this.openModal}
                  >
                    <span className="play-icon"></span>
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Brand Area  */}
        <div className="rn-brand-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandOne branstyle="branstyle--2" />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}

        {/* Start Pricing Tbale Area  */}

        {/* End Pricing Tbale Area  */}

        {/* Start Blog Area */}
        {/* <div className="rn-blog-area pt--120 pb--80 bg_color--1">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center">
                  <h2 className="title">Latest News</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt--60">
              {PostList.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <a href="/blog-details">
                        <img
                          className="w-100"
                          src={`/assets/images/blog/blog-${value.images}.jpg`}
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    <div className="content">
                      <p className="blogtype">{value.category}</p>
                      <h4 className="title">
                        <a href="/blog-details">{value.title}</a>
                      </h4>
                      <div className="blog-btn">
                        <a className="rn-btn text-white" href="/blog-details">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        {/* End Blog Area */}

        {/* Start call To Action  */}
        <CallAction />
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default CorporateBusiness;
